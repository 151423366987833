import React from "react"
import { Button } from "reactstrap"
import { pdf } from "@react-pdf/renderer"

import ReceiptsPDF from "../Receipts/ReceiptsPDF"
import api from "../../api"

const EmailReceiptButton = ({ purchase, data }) => {
  const sendReceipt = async () => {
    try {
      const pdfDocument = (
        <ReceiptsPDF
          purchase={purchase}
          groupName={purchase.groupName}
          total={purchase.total}
        />
      )

      const pdfBlob = await pdf(pdfDocument).toBlob()

      const formData = new FormData()
      formData.append("pdf", pdfBlob, "receipt.pdf")
      formData.append("email", `${data.net_id}@byu.edu`)

      const res = await api.post(process.env.REACT_APP_EMAIL_RECEIPT, formData)
    } catch (err) {
      console.error("Failed to send email", err)
    }
  }
  return <Button onClick={sendReceipt}>Email Receipt</Button>
}

export default EmailReceiptButton
