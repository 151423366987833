//Created by Logan Cropper 7/31/24

import React, { useState, Fragment } from "react"
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap"
import NewProjectTDPForm from "./NewProjectTDPForm"
import styles from "../Lander/Lander.module.css"

const NewProjectTDPModal = ({ create, project, resetState }) => {
  const [modal, setModal] = useState(false)

  const toggle = () => {
    setModal(!modal)
  }

  return (
    <Fragment>
      {!create ? (
        <Button color="primary" className="m-4" onClick={toggle}>
          Edit
        </Button>
      ) : (
        <Button block className={styles["project-button"]} onClick={toggle}>
          <img
            src={`${process.env.PUBLIC_URL}/static/images/3Dprint_image.png`}
            alt="Project 1"
            className={styles["project-image"]}
          />
          <div className={styles["project-text"]}>3D Print Request</div>
        </Button>
      )}
      <Modal isOpen={modal} toggle={toggle} size="lg">
        <ModalHeader toggle={toggle}>
          {create ? "New 3D Print Request" : "Edit"}
        </ModalHeader>
        <ModalBody>
          <NewProjectTDPForm
            toggle={toggle}
            project={project}
            resetState={resetState}
            create={create}
          />
        </ModalBody>
      </Modal>
    </Fragment>
  )
}

export default NewProjectTDPModal
