import React, { Fragment, useState, useEffect } from "react"
import { Button, Col, Modal, ModalBody, ModalHeader, Row } from "reactstrap"
import Select from "react-select"
import { DayPicker } from "react-day-picker"
import "react-day-picker/style.css"

import api from "../../api"
import { RECEIPTS_URL } from "../../constants"
import ReceiptReport from "../Receipts/ReceiptReport"

const GroupReportModal = ({ groups }) => {
  const [modal, setModal] = useState(false)
  const [selectedGroups, setSelectedGroups] = useState([])
  const [dates, setDates] = useState([])
  const [showReport, setShowReport] = useState(false)
  const [receipts, setReceipts] = useState([])

  const toggle = () => {
    setModal(!modal)
    setShowReport(false)
    setDates([])
    setSelectedGroups([])
    setReceipts([])
  }

  const fetchReceipts = async () => {
    try {
      const data = await api.get(RECEIPTS_URL)
      setReceipts(data.data)
    } catch (err) {
      console.log("Failed to retrieve items")
    }
  }

  const handleChange = (selected) => {
    setSelectedGroups(selected)
  }

  const filterReceipts = (receipts, selectedGroups, dates) => {
    // Extract group names from selected groups
    const selectedGroupNames = selectedGroups.map((group) => group.label)

    // console.log(receipts, selectedGroups, dates)

    return receipts.filter((receipt) => {
      const { groupPurchaseName, dateOfPurchase } = receipt

      // Check if group names are provided and match
      const groupMatch =
        selectedGroupNames.length > 0
          ? selectedGroupNames.includes(groupPurchaseName) // Only match if group names are provided
          : groupPurchaseName !== null // Only include receipts with a groupPurchaseName

      // Check date range
      const dateMatch =
        dates.length === 0 ||
        (dates.length === 2 &&
          isDateInRange(new Date(dateOfPurchase), dates[0], dates[1]))

      return groupMatch && dateMatch // Return receipts that match both criteria
    })
  }

  const isDateInRange = (dateToCheck, startDate, endDate) => {
    return dateToCheck >= startDate && dateToCheck <= endDate
  }

  const handleClick = () => {
    console.log(filterReceipts(receipts, selectedGroups, dates))
    setShowReport(true)
  }

  const formatDate = (date) => {
    return date ? new Date(date).toLocaleDateString() : ""
  }

  const resetState = () => {
    fetchReceipts()
  }

  useEffect(() => {
    resetState()
  }, [modal])

  return (
    <Fragment>
      <Button onClick={toggle} className="m-4" color="primary">
        Generate Group Report
      </Button>
      <Modal
        isOpen={modal}
        toggle={toggle}
        className={showReport ? "modal-lg" : "modal-md"}
      >
        <ModalHeader toggle={toggle}>Generate Group Report</ModalHeader>
        <ModalBody>
          {!showReport && (
            <div>
              <Row>
                <Select
                  className="select-dropdown"
                  isMulti
                  options={groups.map((group) => ({
                    value: group.pk,
                    label: group.name,
                  }))}
                  value={selectedGroups}
                  onChange={handleChange}
                  placeholder="Select groups"
                  closeMenuOnSelect={false}
                />
              </Row>
              <Row>
                <Col>
                  <DayPicker
                    className="day-picker"
                    mode="multiple"
                    selected={dates}
                    onSelect={setDates}
                    max={2}
                    hideNavigation
                    captionLayout="dropdown"
                  />
                </Col>
              </Row>
              <Button onClick={handleClick}>Filter Receipts</Button>
            </div>
          )}
          {showReport && (
            <ReceiptReport
              receipts={filterReceipts(receipts, selectedGroups, dates)}
              startDate={formatDate(dates[0])}
              endDate={formatDate(dates[1])}
              groupList={selectedGroups}
            />
          )}
        </ModalBody>
      </Modal>
    </Fragment>
  )
}

export default GroupReportModal
