import React, { createContext, useState, useEffect } from "react"
import api from "../../api"
import { CART_URL } from "../../constants"

import axios from "axios"

const AuthContext = createContext()

const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [currentUser, setCurrentUser] = useState()
  const [isLoggedIn, setIsLoggedIn] = useState(false)

  // Check if the user is authenticated when the component mounts
  useEffect(() => {
    axios
      .get("/api/auth/current_user/", { withCredentials: true })
      .then((response) => {
        setCurrentUser(response.data)
        setIsAuthenticated(response.data.is_authenticated)
        setIsLoggedIn(true)
      })
      .catch((error) => {
        console.error("Error fetching user:", error)
        setIsAuthenticated(false)
      })
  }, [])

  // const login = async (username, password) => {
  //   try {
  //     const response = await api.post("api/auth/login/", {
  //       username,
  //       password,
  //     })
  //     if (response.data.message === "Login successful!") {
  //       setIsAuthenticated(true)
  //       const user = await current_user()
  //       await setCurrentUser(user.data)
  //       await api.get(CART_URL)
  //       console.log(user)
  //       return true // Indicate success
  //     } else {
  //       throw new Error("Login failed") // Throw an error if the login is not successful
  //     }
  //   } catch (error) {
  //     console.error("Login failed", error)
  //     return false // Indicate failure
  //   }
  // }

  // const loginBypass = async () => {
  //   setIsAuthenticated(true) //Bypass login for testing
  //   console.log("TEMPORARY LOGIN WITH NO COOKIES")
  // }

  // const logout = async () => {
  //   try {
  //     await api.post("api/auth/logout/")
  //     setIsAuthenticated(false)
  //     setCurrentUser()
  //   } catch (error) {
  //     console.error("Logout failed", error)
  //   }
  // }

  // const current_user = async () => {
  //   try {
  //     const user = await api.get("api/auth/current_user/")
  //     return user
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }

  // const testCookies = async () => {
  //   try {
  //     const response = await api.get(
  //       "http://localhost:8000/api/auth/test_cookies/"
  //     )
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        currentUser,
        isLoggedIn,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export { AuthContext, AuthProvider }
