import React, { useState } from "react"

import {
  Modal,
  ModalHeader,
  Button,
  ModalBody,
  FormGroup,
  Label,
  Form,
} from "reactstrap"

import Datetime from "react-datetime"
import moment from "moment"

import api from "../../api"
import { LOANED_ITEMS_URL } from "../../constants"

const CheckInModal = ({ pk, resetState }) => {
  const [modal, setModal] = useState(false)
  const [startDate, setStartDate] = useState(new Date())

  const toggle = () => {
    setModal((prevModal) => !prevModal)
  }
  const handleDateChange = (date) => {
    if (moment(date).isValid()) {
      setStartDate(date) // Keep startDate as a Date object
    } else {
      console.error("Invalid date:", date)
    }
  }

  const handleCheckIn = async (e) => {
    e.preventDefault()
    try {
      const data = await api.put(`${LOANED_ITEMS_URL}${pk}`)
    } catch (err) {
      console.log("Failed to retrieve items")
    }
    resetState()
  }

  return (
    <>
      <Button color="primary" onClick={toggle}>
        Check-In
      </Button>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Enter item quantity</ModalHeader>
        <ModalBody>
          {/* <p>{JSON.stringify(cartType)}</p> */}
          <Form onSubmit={handleCheckIn}>
            <FormGroup>
              <Label for="datePicker">Date:</Label>
              <Datetime value={startDate} onChange={handleDateChange} />
            </FormGroup>
            <Button type="submit">Submit</Button>
          </Form>
        </ModalBody>
      </Modal>
    </>
  )
}

export default CheckInModal
