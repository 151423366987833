import React, { useState, useRef } from "react"
import {
  Button,
  Modal,
  Form,
  Label,
  FormGroup,
  Input,
  ModalBody,
  ModalHeader,
} from "reactstrap"

import api from "../../api"

const MiscModal = ({ resetState }) => {
  const [modal, setModal] = useState(false)
  const [quantity, setQuantity] = useState()
  const [name, setName] = useState("")
  const [price, setPrice] = useState()
  const inputRef = useRef(null)

  const onNameChange = (e) => {
    setName(e.target.value)
  }

  const onPriceChange = (e) => {
    setPrice(e.target.value)
  }

  const onQuantityChange = (e) => {
    setQuantity(e.target.value)
  }

  const toggle = () => {
    setModal(!modal)
    setQuantity(null)
    setName("")
    setPrice(null)
    resetState()
  }

  const createMiscItem = async (e) => {
    const payLoad = {
      miscName: name,
      miscPrice: price,
      quantity: quantity,
      project_pk: null,
    }

    try {
      const response = await api.post(
        process.env.REACT_APP_CART_ITEM__CREATE_URL,
        payLoad
      )
      console.log(response)
    } catch (error) {
      console.error("Error during test request:", error)
    }
  }

  const handleEnter = (event) => {
    if (event.key === "Enter") {
      createMiscItem()
      toggle()
    }
  }

  return (
    <>
      <Button
        onClick={toggle}
        style={{ minWidth: "80px" }}
        className="m-1 mb-4 mt-4 float-end"
      >
        Misc Purchase
      </Button>
      <Modal
        isOpen={modal}
        toggle={toggle}
        onOpened={() => inputRef.current?.focus()}
      >
        <ModalHeader toggle={toggle}>Miscellaneous Purchase</ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <Label for="name">Item Name</Label>
              <Input
                type="text"
                name="name"
                onChange={onNameChange}
                value={name}
                innerRef={inputRef}
              />
              <Label for="price">Price</Label>
              <Input
                type="number"
                name="price"
                onChange={onPriceChange}
                value={price}
              />
              <Label for="quantity">Quantity</Label>
              <Input
                type="number"
                name="quantity"
                onChange={onQuantityChange}
                value={quantity}
                onKeyDown={handleEnter}
              />
            </FormGroup>

            <Button
              onClick={() => {
                toggle()
                createMiscItem()
                resetState()
              }}
            >
              Submit
            </Button>
          </Form>
        </ModalBody>
      </Modal>
    </>
  )
}

export default MiscModal
