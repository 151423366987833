import React, { useState, useEffect } from "react"

import axios from "axios"
import { createColumnHelper } from "@tanstack/react-table"
import { Button } from "reactstrap"

import { ITEM_URL } from "../../constants"

import Table from "../Table/Table"

const ReorderTab = () => {
  const [items, setItems] = useState([])

  const columnHelper = createColumnHelper()

  const columns = [
    columnHelper.accessor("name", {
      cell: (info) => info.getValue(),
      header: () => <span>Name</span>,
      width: "15%",
    }),
    columnHelper.accessor("image", {
      cell: (info) => (
        <img
          src={info.getValue()}
          alt="Failed to Load"
          style={{ width: "100px", height: "100px" }}
        />
      ),
      header: () => <span></span>,
      width: "10%",
    }),
    columnHelper.accessor("description", {
      header: () => "Description",
      cell: (info) => info.renderValue(),
      width: "15%",
    }),
    columnHelper.accessor("location", {
      header: "Location",
      width: "8%",
    }),
    columnHelper.accessor("quantity", {
      header: () => <span>Stock</span>,
      width: "7%",
    }),
    columnHelper.accessor("price", {
      cell: (info) => {
        const priceValue = info.getValue()
        // Format the price to always show two decimal places
        return priceValue !== null && priceValue !== undefined
          ? `$${priceValue.toFixed(2)}`
          : "$0.00" // You can adjust this for how you want to display empty values
      },
      header: "Price",
      width: "7%",
    }),
    columnHelper.accessor("dataSheet", {
      header: "Datasheet",
      width: "7%",
      cell: (info) =>
        info.getValue() !== "" ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Button onClick={() => window.open(info.getValue(), "_blank")}>
              Sheet
            </Button>
          </div>
        ) : null, // If the dataSheet is empty, render nothing
    }),
    columnHelper.accessor("max_quantity", {
      header: () => "Max Quantity",
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("threshold", {
      header: () => "Threshold",
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("amountNeeded", {
      header: () => "Amount Needed",
      cell: ({ row }) => {
        return row.original.threshold - row.original.quantity
      },
    }),
  ]

  const getItems = async () => {
    try {
      const res = await axios.get(ITEM_URL)
      let refilledItems = []
      for (let item of res.data) {
        if (item.quantity < item.threshold) {
          refilledItems.push(item)
        }
      }
      setItems(refilledItems)
      console.log(refilledItems)
    } catch (err) {
      console.log("Failed to retrieve items")
    }
  }

  const resetState = () => {
    // Reset the state
    getItems()
  }

  useEffect(() => {
    resetState()
  }, [])
  return (
    <>
      <h1>Reorder tab cool</h1>
      <Table
        parentData={items}
        searchable={true}
        columns={columns}
        paginate={true}
        fixedLayout={true}
      />
    </>
  )
}

export default ReorderTab
