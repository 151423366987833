// LanderTab.js
import React, { Component, Fragment } from "react"
import { Container, Row, Col, Button } from "reactstrap"
import styles from "./Lander.module.css"
import NewProjectTDPModal from "../Projects/NewProjectTDPModal"
import NewProjectPCBModal from "../Projects/NewProjectPCBModal"
import NewProjectLCModal from "../Projects/NewProjectLCModal"

function LanderTab(props) {
  let duplication_id = 10000000

  const fetchProtectedResource = async () => {
    try {
      // const token = await getToken()
      // setToken(token)

      const response = await fetch(
        "https://api-sandbox.byu.edu/domains/campus-cards/v1/cards/000123316", //57... is Ty's byu_id
        {
          headers: {
            Authorization:
              "Bearer ory_at_u8IsuM4r9wG8p07KEmTHybIoPnPG-cCgnBLpERvPNOI.VbjNVtqN73vX9KeIFP2UREu_i4fwl7MntrHA4V5uFMQ",
          },
        }
      )

      const data = await response.json()
      // setData(data)
      console.log(data)
    } catch (error) {
      console.error("Error fetching the protected resource:", error)
    }
  }

  const handlePurchase = async () => {
    console.log("hi")
    duplication_id += 1
    const url =
      "https://api-sandbox.byu.edu/domains/campus-cards/v1/cards/579200430/transactions"
    const headers = {
      "Content-Type": "application/json",
      Authorization:
        "Bearer ory_at_u8IsuM4r9wG8p07KEmTHybIoPnPG-cCgnBLpERvPNOI.VbjNVtqN73vX9KeIFP2UREu_i4fwl7MntrHA4V5uFMQ",
    }
    const body = JSON.stringify({
      terminal_id: "Experiential Learning Center",
      type: "deposit",
      amount_type: "cents",
      amount: 1,
      sales_tax_amount: 0,
      deduplication_id: 10989344, //needs to be changed every time
      dangerously_ignore_duplicate_transactions: false,
    })

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: headers,
        body: body,
      })

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`)
      }

      const data = await response.json()
      console.log(JSON.stringify(data))
      return data
    } catch (error) {
      console.error("Error fetching token:", error)
    }
  }

  return (
    <Fragment>
      <Row className="p-5">
        <Col>
          <NewProjectTDPModal create={true} />
        </Col>
        <Col>
          <NewProjectPCBModal create={true} />
        </Col>
        <Col>
          <NewProjectLCModal create={true} />
        </Col>
      </Row>
    </Fragment>
  )
}

export default LanderTab
