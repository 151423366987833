import React, { Fragment } from "react"
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
} from "@react-pdf/renderer"

// Create styles
const styles = StyleSheet.create({
  page: {
    backgroundColor: "white",
    padding: 50,
  },
  header: {
    fontSize: 24,
    textAlign: "center",
    marginBottom: 10,
  },
  date: {
    fontSize: 14,
    textAlign: "center",
  },
  group_name: {
    fontSize: 14,
    textAlign: "left",
    fontWeight: "bold",
    marginTop: 20,
    borderBottomWidth: "2px",
  },
  item_row: {
    fontSize: 10,
    marginLeft: 20,
    display: "flex",
    flexDirection: "row",
  },
  item_row_header: {
    fontSize: 12,
    marginLeft: 20,
    display: "flex",
    flexDirection: "row",
  },
  item_column_1: {
    flexBasis: "30%",
    flexGrow: 1,
  },
  item_column_2: {
    flexBasis: "20%",
  },
  item_column_3: {
    flexBasis: "20%",
  },
  item_column_4: {
    flexBasis: "10%",
    textAlign: "right",
  },
})

// Create Document Component
const ReceiptReport = ({ receipts, startDate, endDate }) => {
  const groupedReceipts = receipts.reduce((acc, receipt) => {
    const groupName = receipt.groupPurchaseName
    if (!acc[groupName]) {
      acc[groupName] = []
    }
    acc[groupName].push(receipt)
    return acc
  }, {})

  return (
    <PDFViewer showToolbar={true} width={"100%"} height={800}>
      <Document title={"Purchases_Report_" + startDate + "_" + endDate}>
        <Page size="letter" style={styles.page}>
          {/* Title header */}
          <View style={styles.header}>
            <Text>GROUP PURCHASES REPORT</Text>
          </View>

          {/* Date range */}
          <View style={styles.date}>
            <Text>
              {startDate ? "From " + startDate : ""}{" "}
              {endDate ? "To " + endDate : ""}
            </Text>
          </View>

          {/* Sections for each group */}
          {Object.entries(groupedReceipts).map(([groupName, groupItems]) => {
            const groupTotal = groupItems.reduce((total, item) => {
              return total + parseFloat(item.total)
            }, 0)

            return (
              <Fragment key={groupName}>
                <View style={styles.group_name}>
                  <Text>Group Name: {groupName}</Text>
                </View>

                {/* Table Headers */}
                <View style={styles.item_row_header}>
                  <Text style={styles.item_column_1}>Purchaser</Text>
                  <Text style={styles.item_column_2}>Item Qty.</Text>
                  <Text style={styles.item_column_3}>Date</Text>
                  <Text style={styles.item_column_4}>Subtotal</Text>
                </View>

                {/* Purchases in table */}
                {groupItems.map((item) => {
                  return (
                    <Fragment key={item.pk}>
                      <View style={styles.item_row}>
                        <Text style={styles.item_column_1}>
                          {item.purchaserName}
                        </Text>
                        <Text style={styles.item_column_2}>
                          {item.receiptItems.length}{" "}
                          {/* Assuming receiptItems has the item count */}
                        </Text>
                        <Text style={styles.item_column_3}>
                          {item.dateOfPurchase}
                        </Text>
                        <Text style={styles.item_column_4}>
                          {parseFloat(item.total).toFixed(2)}
                        </Text>
                      </View>
                    </Fragment>
                  )
                })}

                {/* Group price total */}
                <View style={styles.item_row_header}>
                  <Text style={styles.item_column_1}></Text>
                  <Text style={styles.item_column_2}></Text>
                  <Text style={styles.item_column_3}>Total:</Text>
                  <Text style={styles.item_column_4}>
                    {groupTotal.toFixed(2)}
                  </Text>
                </View>
              </Fragment>
            )
          })}
        </Page>
      </Document>
    </PDFViewer>
  )
}

export default ReceiptReport
