import React from "react";
import {Input} from "reactstrap";

const TableSearch = (props) => {
  const inputStyle = { marginBottom: "16px", padding: "8px", width: "100%" };
  return (
    <Input
      type="text"
      className="w-25"
      value={props.searchTerm}
      onChange={(e) => props.setSearchTerm(e.target.value)}
      placeholder="Search..."
      style={inputStyle}
    />
  );
};

export default TableSearch;
