import React, { useState, useEffect } from "react"
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Alert,
  Row,
  Col,
} from "reactstrap"

import api from "../../api"

const NewProjectTDPForm = ({ project, toggle, resetState, create }) => {
  const STATUS_CHOICES = [
    ["UN", "Unfulfilled"],
    ["IP", "In Progress"],
    ["CM", "Completed"],
    ["AR", "Archived"],
    ["CA", "Cancelled"],
  ]

  const PURPOSE_CHOICES = [
    ["PE", "Personal"],
    ["CL", "Class"],
    ["RE", "Research"],
  ]

  const [formData, setFormData] = useState({
    pk: 0,
    status: "UN",
    purpose: "",
    customerName: "",
    customerEmail: "",
    date: new Date().toISOString().slice(0, 10),
    quantity: 0,
    infill: 0,
    color1: "",
    color2: "",
    comments: "",
    file: null,
  })
  const [errorMessage, setErrorMessage] = useState("")
  const [newFileSelected, setNewFileSelected] = useState(false)

  useEffect(() => {
    if (project) {
      setFormData({
        pk: project.pk,
        status: project.status,
        purpose: project.purpose,
        customerName: project.customerName,
        customerEmail: project.customerEmail,
        date: project.date,
        quantity: project.quantity,
        infill: project.infill,
        color1: project.color1,
        color2: project.color2,
        comments: project.comments,
        file: project.file,
      })
    }
  }, [])

  const onChange = (e) => {
    if (e.target.name === "file") {
      setFormData({ ...formData, file: e.target.files[0] })
      setNewFileSelected(true)
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value })
    }
  }

  const sendReceivedEmail = async () => {
    try {
      const data = { email: formData.customerEmail, projectType: "3D Printing" }

      const res = await api.post(process.env.REACT_APP_PROJECT_RECEIVED, data)
    } catch (err) {
      console.error("Failed to send email", err)
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    if (
      !formData.customerName ||
      !formData.customerEmail ||
      !formData.file ||
      !formData.quantity ||
      !formData.infill ||
      !formData.color1
    ) {
      setErrorMessage("Please fill out all fields.")
      return
    }

    if (formData.infill < 5 || formData.infill > 100) {
      setErrorMessage("Please specify an infill value between 5-100")
      return
    }

    const data = new FormData()
    Object.keys(formData).forEach((key) => {
      if (formData[key] !== null && (key !== "file" || newFileSelected)) {
        data.append(key, formData[key])
      }
    })

    const request = project
      ? api.put(`${process.env.REACT_APP_PROJECT_TDP_URL}${formData.pk}`, data)
      : api.post(`${process.env.REACT_APP_PROJECT_TDP_URL}`, data)

    request
      .then(() => {
        sendReceivedEmail()
        toggle()
        resetState()
      })
      .catch((error) => {
        console.error(
          `Error ${project ? "editing" : "creating"} request: `,
          error
        )
        setErrorMessage(
          `Error ${project ? "editing" : "creating"} request. Please try again.`
        )
      })
  }

  const defaultIfEmpty = (value) => (value === "" ? "" : value)

  return (
    <Form onSubmit={handleSubmit}>
      {errorMessage && <Alert color="danger">{errorMessage}</Alert>}

      <h5>Request Information:</h5>
      <Row>
        <Col>
          <FormGroup>
            <Label for="customerName">Name:</Label>
            <Input
              type="text"
              name="customerName"
              onChange={onChange}
              value={defaultIfEmpty(formData.customerName)}
              disabled={!create}
            />
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <Label for="customerEmail">Email:</Label>
            <Input
              type="text"
              name="customerEmail"
              onChange={onChange}
              value={defaultIfEmpty(formData.customerEmail)}
              disabled={!create}
            />
          </FormGroup>
        </Col>
      </Row>

      {create ? (
        <>
          <h5 className="mt-4">Print File:</h5>
          <FormGroup>
            <Label for="file">We accept .stl, .3mf, .stp</Label>
            <Input
              type="file"
              name="file"
              onChange={onChange}
              disabled={!create}
            />
          </FormGroup>
        </>
      ) : (
        <Row>
          <Col>
            <strong>Project File: </strong>
            {project.file_name}
          </Col>
          <Col>
            <Button href={project.file} download>
              Download
            </Button>
          </Col>
        </Row>
      )}

      <h5 className="mt-5">Print Settings:</h5>
      <Row>
        <Col>
          <FormGroup>
            <Label for="quantity">Print Quantity:</Label>
            <Input
              className="w-25"
              type="number"
              name="quantity"
              onChange={onChange}
              value={formData.quantity}
            />
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <Label for="color1">Filament Color (1st choice):</Label>
            <Input
              type="text"
              name="color1"
              onChange={onChange}
              value={defaultIfEmpty(formData.color1)}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <FormGroup>
            <Label for="infill">Infill %: (Between 5-100)</Label>
            <Input
              className="w-25"
              type="number"
              name="infill"
              onChange={onChange}
              value={formData.infill}
            />
          </FormGroup>
        </Col>
      </Row>
      <FormGroup>
        <Label for="comments">Additional comments:</Label>
        <Input
          name="comments"
          type="textarea"
          placeholder="If there are any additional settings you'd like to specify (layer height, speed, supports, orientation, etc), please note them here. Most prints will be fine with the default settings we use."
          onChange={onChange}
          value={defaultIfEmpty(formData.comments)}
        />
      </FormGroup>
      <Button>Send</Button>
    </Form>
  )
}

export default NewProjectTDPForm
