//Created by Logan Cropper 8/9/24

import React, { useState, useEffect, useContext, Fragment } from "react"
import { Col, Container, Row } from "reactstrap"
import { AuthContext } from "../DAO/AuthContext"

import { PROJECT_LC_URL } from "../../constants"
import Table from "../Table/Table"
import { createColumnHelper } from "@tanstack/react-table"
import ConfirmRemovalModal from "../ConfirmRemovalModal"
import NewProjectLCModal from "./NewProjectLCModal"
import axios from "axios"
//import api from "../../api"

import StartJobModal from "./StartJobModal"
import InProgressModal from "./InProgressModal" // Import different modals
import CompleteModal from "./CompleteModal"
import EditStatusButton from "./EditStatusButton"
import MoveToCancelledModal from "./MoveToCancelledModal"

const ProjectLCList = ({ statusFilter }) => {
  const columnHelper = createColumnHelper()
  const [projects, setProjects] = useState([])

  const resetState = () => {
    getProjects()
  }

  const getModalComponent = (status, row) => {
    switch (status) {
      case "UN":
        return (
          <StartJobModal
            project={row?.original}
            type={"PCB Mill"}
            resetState={resetState}
            projectType={"lc"}
          />
        )
      case "IP":
        return (
          <InProgressModal
            project={row?.original}
            resetState={resetState}
            projectType={"lc"}
          />
        )
      case "CP":
        return (
          <CompleteModal
            project={row?.original}
            resetState={resetState}
            projectType={"lc"}
          />
        )
      case "AR":
        return (
          <EditStatusButton
            project={row?.original}
            resetState={resetState}
            projectType={"lc"}
          />
        )
      case "CN":
        return (
          <EditStatusButton
            project={row?.original}
            resetState={resetState}
            projectType={"lc"}
          />
        )
      default:
        return null
    }
  }

  const columns = [
    columnHelper.accessor("button", {
      cell: ({ row }) => getModalComponent(statusFilter, row),
      header: () => <span></span>,
    }),
    columnHelper.accessor("purpose", {
      cell: (info) => info.getValue(),
      header: () => <span>Purpose</span>,
    }),
    columnHelper.accessor("quantity", {
      cell: (info) => info.getValue(),
      header: () => <span>Quantity</span>,
    }),
    columnHelper.accessor("customerName", {
      cell: (info) => info.getValue(),
      header: () => <span>Customer Name</span>,
    }),
    columnHelper.accessor("customerEmail", {
      cell: (info) => info.getValue(),
      header: () => <span>Customer Email</span>,
    }),
    columnHelper.accessor("date", {
      cell: (info) => info.getValue(),
      header: () => <span>Date</span>,
    }),
    columnHelper.accessor("buttons", {
      cell: ({ row }) => {
        return (
          <Row>
            <Col>
              <NewProjectLCModal
                create={false}
                project={row.original}
                resetState={resetState}
              />
            </Col>
            <Col>
              <EditStatusButton
                project={row.original}
                resetState={resetState}
                projectType={"lc"}
              />
            </Col>
            <Col>
              <MoveToCancelledModal
                project={row.original}
                resetState={resetState}
                projectType={"lc"}
              />
            </Col>
          </Row>
        )
      },
      header: () => <span>Price</span>,
    }),
  ]

  // Modify the width of the "File" column
  columns[1].width = 50

  useEffect(() => {
    getProjects()
  }, [])

  const getProjects = async () => {
    try {
      const result = await axios.get(PROJECT_LC_URL)
      setProjects(result.data)
    } catch (error) {
      console.error("Error fetching data:", error)
    }
  }

  const filteredProjects = projects.filter((project) => {
    if (project.status === statusFilter) {
      return true
    } else {
      return false
    }
  })

  return (
    <Container className="m-0">
      <Table columns={columns} parentData={filteredProjects} />
    </Container>
  )
}

export default ProjectLCList
