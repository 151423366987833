// 3/6/24 - Modified - Tyler Perkins
// 5/20/24 - Modified - Logan Cropper

import React, { useState } from "react"
import { Modal, ModalHeader, Button, ModalFooter, ModalBody } from "reactstrap"
import api from "../api"

const ConfirmRemovalModal = ({
  apiUrl,
  pk,
  subModelType,
  resetState,
  itemName,
}) => {
  const [modal, setModal] = useState(false)

  // Toggle modal visibility
  const toggle = () => setModal((prevModal) => !prevModal)

  const deleteItem = (pk) => {
    const apiUrlWithPk = subModelType
      ? `${apiUrl}${pk}/${subModelType}`
      : `${apiUrl}${pk}`

    api.delete(apiUrlWithPk).then(() => {
      resetState()
      toggle()
    })
  }

  return (
    <>
      <Button color="danger" onClick={toggle}>
        Remove
      </Button>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>
          Do you really want to delete this item?
        </ModalHeader>

        <ModalBody>
          <p>{itemName}</p>
          <p>ID: {JSON.stringify(pk)}</p>
        </ModalBody>

        <ModalFooter>
          <Button type="button" onClick={toggle}>
            Cancel
          </Button>
          <Button type="button" color="primary" onClick={() => deleteItem(pk)}>
            Yes
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default ConfirmRemovalModal
