import React from "react";
import {Button, Input} from "reactstrap";

const TablePages = ({
  setPageIndex,
  pageIndex,
  pageCount,
  pageSize,
  setPageSize,
}) => {
  return (
    <div className="mt-3">
      <div className="pagination">
        <Button
          outline
          className="m-1"
          onClick={() => setPageIndex(0)}
          disabled={pageIndex === 0}
        >
          {"<<"}
        </Button>{" "}

        <Button
          outline
          className="m-1"
          onClick={() => setPageIndex((old) => Math.max(old - 1, 0))}
          disabled={pageIndex === 0}
        >
          {"<"}
        </Button>{" "}
        <span className="mt-2 ms-3 me-3">
          Page{" "}
          <strong>
            {pageIndex + 1} of {pageCount}
          </strong>{" "}
        </span>
        <Button
          outline
          className="m-1"
          onClick={() =>
            setPageIndex((old) => Math.min(old + 1, pageCount - 1))
          }
          disabled={pageIndex === pageCount - 1}
        >
          {">"}
        </Button>{" "}
        <Button
          outline
          className="m-1"
          onClick={() => setPageIndex(pageCount - 1)}
          disabled={pageIndex === pageCount - 1}
        >
          {">>"}
        </Button>{" "}
        <span className="mt-2 ms-2">
            Items per page:
        </span>
        <Input
          type="select"
          className="m-1"
          style={{width: 70}}
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
            setPageIndex(0); // Reset to first page when page size changes
          }}
        >
          {[10, 20, 30, 40, 50].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              {pageSize}
            </option>
          ))}
        </Input>
      </div>
    </div>
  );
};

export default TablePages;
