// 5/7/24 - Tyler Perkins
// Logic for adding a loan to the loan cart.

import React, { useState } from "react"
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap"
import LoanForm from "./LoanForm"

const AddToLoanCartModal = ({ item, isEquipment, resetState }) => {
  const [modal, setModal] = useState(false)

  // Toggle the modal's visibility
  const toggle = () => setModal((prevModal) => !prevModal)

  // Determine if the cartType is item or equipment
  // const cartType = item || equipment
  // const isEquipment = Boolean(equipment)

  return (
    <>
      <Button color="primary" onClick={toggle}>
        Loan
      </Button>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Enter item quantity</ModalHeader>
        <ModalBody>
          {/* <p>{JSON.stringify(cartType)}</p> */}
          <LoanForm
            resetState={resetState}
            toggle={toggle}
            item={item}
            isEquipment={isEquipment}
          />
        </ModalBody>
      </Modal>
    </>
  )
}

export default AddToLoanCartModal
