import { StyleSheet } from "@react-pdf/renderer"

const styles = StyleSheet.create({
  page: {
    backgroundColor: "white",
    padding: 10,
  },
  header: {
    fontSize: 14,
    textAlign: "center",
  },
  date: {
    fontSize: 10,
    textAlign: "center",
    marginTop: 5,
  },
  purchase_info: {
    fontSize: 8,
    marginTop: 5,
    textAlign: "left",
    paddingHorizontal: 5,
  },
  table_header: {
    fontSize: 8,
    display: "flex",
    flexDirection: "row",
    borderTopWidth: "1px",
    borderBottomWidth: "1px",
    paddingVertical: 2,
    marginBottom: 5,
  },
  table_footer: {
    fontSize: 8,
    display: "flex",
    flexDirection: "row",
    borderTopWidth: "1px",
    marginTop: 5,
    paddingVertical: 2,
  },
  table_row: {
    fontSize: 8,
    display: "flex",
    flexDirection: "row",
    paddingVertical: 1,
  },
  table_column_1: {
    flexBasis: "15%",
  },
  table_column_2: {
    flexBasis: "35%",
    flexGrow: 1,
    paddingRight: 2,
    overflow: "visible", // Ensures overflow isn't hidden
    lineHeight: 1.2,
    fontSize: 6,
  },
  table_column_3: {
    flexBasis: "25%",
  },
  table_column_4: {
    flexBasis: "25%",
  },
})

export default styles
