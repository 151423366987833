import React, { useState } from "react"

import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Input,
  FormGroup,
  Label,
} from "reactstrap"

import api from "../../api"

const MoveToCancelledModal = ({ project, resetState, projectType }) => {
  const [modal, setModal] = useState(false)
  const [emailText, setEmailText] = useState("")

  const toggle = () => {
    setModal(!modal)
  }

  const sendCanceledEmail = async () => {
    try {
      let projectTypeName = ""

      // Map project type to corresponding string
      if (projectType === "tdp") {
        projectTypeName = "3D Print"
      } else if (projectType === "pcb") {
        projectTypeName = "PCB Milling"
      } else if (projectType === "lc") {
        projectTypeName = "Laser Cut"
      }

      const data = {
        email: project.customerEmail,
        projectType: projectTypeName,
        body: emailText,
      }

      const res = await api.post(process.env.REACT_APP_CANCEL_PROJECT, data)
    } catch (err) {
      console.error("Failed to send email", err)
    }
  }

  const cancelProject = async () => {
    try {
      await sendCanceledEmail()
      await api.post(
        `${process.env.REACT_APP_PROJECT_URL}${projectType}/update_job/${project.pk}`,
        { status_type: "CN" }, // Body of the request
        {
          headers: {
            "Content-Type": "application/json", // Ensure you're sending JSON data
          },
        }
      )
      toggle() // Close the modal
      resetState() // Refresh the list of items
    } catch (error) {
      console.error("Error canelling project:", error)
    }
  }

  const onChange = (e) => {
    setEmailText(e.target.value)
  }

  return (
    <div>
      <Button onClick={toggle} className="m-4" color="danger">
        Cancel
      </Button>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>
          Cancel project for {project.customerName}
        </ModalHeader>

        <ModalBody>
          <FormGroup>
            <Label for="exampleText">
              Explain why this project has been canceled
            </Label>
            <Input
              id="cancelEmail"
              name="cancelEmail"
              type="textarea"
              value={emailText}
              onChange={onChange}
            />
          </FormGroup>
        </ModalBody>

        <ModalFooter>
          <Button type="button" onClick={toggle}>
            Close
          </Button>
          <Button type="button" color="primary" onClick={cancelProject}>
            Cancel Project and Email Requester
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}

export default MoveToCancelledModal
