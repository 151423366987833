//Updated to modern React 07/26/2024

import React, { useState, Fragment } from "react"
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap"
import ItemForm from "./ItemForm"

const ItemModal = ({ create, resetState, item }) => {
  const [modal, setModal] = useState(false)

  const toggle = () => {
    setModal((prevModal) => !prevModal)
  }

  const title = create ? "Creating New Item" : "Editing Item"
  const button = create ? (
    <Button color="primary" onClick={toggle} className="m-4">
      Create New Item
    </Button>
  ) : (
    <Button onClick={toggle} className="m-4">
      Edit
    </Button>
  )

  return (
    <Fragment>
      {button}
      <Modal isOpen={modal} toggle={toggle} size="lg">
        <ModalHeader toggle={toggle}>{title}</ModalHeader>
        <ModalBody>
          <ItemForm resetState={resetState} toggle={toggle} item={item} />
        </ModalBody>
      </Modal>
    </Fragment>
  )
}

export default ItemModal
