import React, { Fragment } from "react"

import { Button, Col, Row } from "reactstrap"

import GroupSelectModal from "./GroupSelectModal"

const GroupFooter = ({ setGroup, group }) => {
  return (
    <>
      <Row>
        <Col>
          <GroupSelectModal setGroup={setGroup} group={group} />
          {group !== null && (
            <Fragment>
              <Button
                color="danger"
                outline
                className="mt-4 mb-4 me-2"
                onClick={() => setGroup(null)}
              >
                Clear
              </Button>
            </Fragment>
          )}
        </Col>
        <Col></Col>
      </Row>
    </>
  )
}

export default GroupFooter
