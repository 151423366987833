import React from "react"

import { createColumnHelper } from "@tanstack/react-table"

import Table from "../Table/Table"
import RemoveCartItemModal from "./RemoveCartItemModal"

const LoanCart = ({ loanData, resetState }) => {
  const columnHelper = createColumnHelper()

  const columns = [
    columnHelper.accessor("name", {
      cell: (info) => info.getValue(),
      header: () => <span>Name</span>,
    }),
    columnHelper.accessor("returnDate", {
      cell: (info) => info.getValue(),
      header: () => <span>Return Date</span>,
    }),
    columnHelper.accessor("quantity", {
      cell: (info) => info.getValue(),
      header: () => <span>Quantity</span>,
    }),
    columnHelper.accessor("pk", {
      cell: ({ row }) => {
        return (
          <RemoveCartItemModal
            item={row.original}
            resetState={resetState}
            isLoan={true}
          />
        )
      },
      header: () => <span></span>,
    }),
  ]
  return (
    <>
      <div className="header">
        <div className="title-text">
          <h3>Loans Cart</h3>
        </div>
      </div>
      <Table
        parentData={loanData}
        columns={columns}
        paginate={false}
        searchable={false}
      />
    </>
  )
}

export default LoanCart
