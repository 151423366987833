import React from "react"

import { flexRender } from "@tanstack/react-table"

const TableFooter = ({ table }) => {
  const tfStyle = {
    border: "1px solid #ddd",
    padding: "8px",
    backgroundColor: "#f2f2f2",
    textAlign: "left",
  }
  return (
    <tfoot>
      {table.getFooterGroups().map((footerGroup) => (
        <tr key={footerGroup.id}>
          {footerGroup.headers.map((footer) => (
            <th key={footer.id} style={tfStyle}>
              {footer.isPlaceholder
                ? null
                : flexRender(
                    footer.column.columnDef.footer,
                    footer.getContext()
                  )}
            </th>
          ))}
        </tr>
      ))}
    </tfoot>
  )
}

export default TableFooter
