import React from "react"
import { flexRender } from "@tanstack/react-table"

const thStyle = {
  border: "1px solid #ddd",
  padding: "8px",
  backgroundColor: "#f2f2f2",
  textAlign: "left",
}

const TableHead = ({ table }) => {
  return (
    <thead>
      {table.getHeaderGroups().map((headerGroup) => (
        <tr key={headerGroup.id}>
          {headerGroup.headers.map((header) => {
            // Combine the static thStyle with any dynamic styles (like width)
            const dynamicStyles = {
              ...thStyle, // existing static styles
              width: header.column.columnDef.width || "auto", // dynamic width if provided
            }

            return (
              <th key={header.id} style={dynamicStyles}>
                {header.isPlaceholder
                  ? null
                  : flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
              </th>
            )
          })}
        </tr>
      ))}
    </thead>
  )
}

export default TableHead
