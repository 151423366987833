import React, { useState, useEffect } from "react"
import { Container, Row, Col, Input } from "reactstrap"
import { createColumnHelper } from "@tanstack/react-table"

import { GROUPS_URL } from "../../constants"
import NewGroupModal from "./NewGroupModal"
import ConfirmRemovalModal from "../ConfirmRemovalModal"
import Table from "../Table/Table"
import GroupReportModal from "./GroupReportModal"
import api from "../../api"

const ImprovedGroupsTab = () => {
  const [groups, setGroups] = useState([])

  const columnHelper = createColumnHelper()

  const columns = [
    columnHelper.accessor("name", {
      cell: (info) => info.getValue(),
      header: () => <span>Name</span>,
    }),
    columnHelper.accessor("description", {
      cell: (info) => info.getValue(),
      header: () => <span>Description</span>,
    }),
    columnHelper.accessor("balance", {
      cell: (info) => info.getValue(),
      header: () => <span>Balance</span>,
    }),
    columnHelper.accessor("buttons", {
      header: () => <span></span>,
      cell: ({ row }) => {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <NewGroupModal
              create={false}
              resetState={resetState}
              group={row.original}
            />
            <ConfirmRemovalModal
              pk={row.original.pk}
              name={row.original.name}
              resetState={resetState}
              apiUrl={GROUPS_URL}
            />
          </div>
        )
      },
    }),
  ]

  const getGroups = async () => {
    try {
      const res = await api.get(GROUPS_URL)
      setGroups(res.data)
    } catch (err) {
      console.log("Failed to retrive groups")
    }
  }

  const resetState = () => {
    // Reset the state
    getGroups()
  }

  useEffect(() => {
    resetState()
  }, [])

  return (
    <>
      <Container className="main-container" fluid="true">
        <div className="title-text">
          <Row className="align-items-center justify-content-between">
            <Col>
              <h2>Groups</h2>
            </Col>
            <Col xs="auto">
              <GroupReportModal groups={groups} />
              <NewGroupModal create={true} resetState={resetState} />
            </Col>
          </Row>
        </div>
        <Table
          parentData={groups}
          searchable={true}
          columns={columns}
          paginate={true}
          fixedLayout={false}
        />
      </Container>
    </>
  )
}

export default ImprovedGroupsTab
