//Updated to modern React 07/26/2024

import React, { useState, useRef, useEffect } from "react"
import { Button, Form, FormGroup, Input, Label } from "reactstrap"

import api from "../../api"

const QuantityForm = ({ item, resetState, toggle }) => {
  const [quantityToOrder, setQuantityToOrder] = useState(null)
  const inputRef = useRef(null)

  // Reflect user interaction with state changes
  const onChange = (e) => {
    setQuantityToOrder(e.target.value)
  }

  const putItemInCart = async (e) => {
    e.preventDefault() // Prevent form submission

    const payLoad = {
      item: item.pk,
      quantity: quantityToOrder,
    }
    try {
      const response = await api.post(
        process.env.REACT_APP_CART_ITEM__CREATE_URL,
        payLoad
      )
    } catch (error) {
      console.error("Error during test request:", error)
    }
  }

  const handleSubmit = (event) => {
    if (event.key === "Enter") {
      putItemInCart()
      toggle()
    }
  }

  useEffect(() => {
    // Focus the input field when the component mounts
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }, [])

  return (
    <Form onSubmit={putItemInCart}>
      <FormGroup>
        <Label for="quantityToOrder">Quantity:</Label>
        <Input
          type="number"
          name="quantityToOrder"
          onChange={onChange}
          value={quantityToOrder}
          innerRef={inputRef}
          onKeyDown={handleSubmit}
        />
      </FormGroup>

      <Button
        onClick={() => {
          putItemInCart()
          toggle()
        }}
      >
        Submit
      </Button>
    </Form>
  )
}

export default QuantityForm
