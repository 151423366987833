import React, { useState, Fragment } from "react"
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap"

import ReceiptsPDF from "./ReceiptsPDF"
import { PDFViewer } from "@react-pdf/renderer"

const ReceiptsModal = ({ purchase, total, groupName }) => {
  const [modal, setModal] = useState(false)

  const toggle = () => {
    setModal(!modal)
  }

  return (
    <Fragment>
      <Button onClick={toggle} className="m-4" color="primary">
        View
      </Button>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Purchase Receipt</ModalHeader>
        <ModalBody>
          <PDFViewer showToolbar={true} width={"100%"} height={600}>
            <ReceiptsPDF
              purchase={purchase}
              total={total}
              groupName={groupName}
            />
          </PDFViewer>
        </ModalBody>
      </Modal>
    </Fragment>
  )
}

export default ReceiptsModal
