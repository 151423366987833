import React, { useState, useEffect } from "react"
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Alert,
  Row,
  Col,
} from "reactstrap"
import { PROJECT_LC_URL } from "../../constants"

import api from "../../api"
import axios from "axios"

const NewProjectLCForm = ({ project, toggle, resetState, create }) => {
  const STATUS_CHOICES = [
    ["UN", "Unfulfilled"],
    ["IP", "In Progress"],
    ["CM", "Completed"],
    ["AR", "Archived"],
    ["CA", "Cancelled"],
  ]

  const PURPOSE_CHOICES = [
    ["PE", "Personal"],
    ["CL", "Class"],
    ["RE", "Research"],
  ]

  const CUT_TYPE_CHOICES = [
    ["VC", "Vector"],
    ["RS", "Raster"],
  ]

  const MATERIAL_CHOICES = ["Acrylic", "Wood", "Foamcore", "Other"]

  const THICKNESS_CHOICES = ['1/8"', '1/4"', '1/2"', "larger"]

  const SOURCE_CHOICES = [
    ["SHW", "Shop Provided (We Pick)"],
    ["SHY", "Shop Provided (You Pick)"],
    ["SP", "Student Provided"],
  ]

  const [formData, setFormData] = useState({
    pk: 0,
    status: "UN",
    purpose: "PE",
    customerName: "",
    customerEmail: "",
    date: new Date().toISOString().slice(0, 10),
    quantity: 0,
    LaserTime: 0,
    material: "Acrylic",
    customMaterial: "",
    thickness: '1/8"',
    cutType: "VC",
    materialSource: "SHW",
    comments: "",
    file: null,
    whereMaterial: "", //Where the student put their material if they are providing it
  })
  const [errorMessage, setErrorMessage] = useState("")
  const [newFileSelected, setNewFileSelected] = useState(false)

  useEffect(() => {
    if (project) {
      setFormData({
        pk: project.pk,
        status: project.status,
        purpose: project.purpose,
        customerName: project.customerName,
        customerEmail: project.customerEmail,
        date: project.date,
        quantity: project.quantity,
        LaserTime: project.LaserTime,
        material: project.material,
        customMaterial: project.customMaterial,
        thickness: project.thickness,
        cutType: project.cutType,
        materialSource: project.materialSource,
        comments: project.comments,
        file: project.file,
        whereMaterial: project.whereMaterial,
      })
    }
  }, [])

  const onChange = (e) => {
    if (e.target.name === "file") {
      setFormData({ ...formData, file: e.target.files[0] })
      setNewFileSelected(true)
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value })
    }
    //console.log(formData);
  }

  const sendReceivedEmail = async () => {
    try {
      const data = {
        email: formData.customerEmail,
        projectType: "Laser Cutting",
      }

      const res = await api.post(process.env.REACT_APP_PROJECT_RECEIVED, data)
    } catch (err) {
      console.error("Failed to send email", err)
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    console.log(formData)
    if (
      !formData.customerName ||
      !formData.customerEmail ||
      !formData.file ||
      !formData.quantity ||
      !formData.material ||
      !formData.thickness ||
      !formData.materialSource ||
      (formData.materialSource == "SP" && !formData.whereMaterial)
    ) {
      setErrorMessage("Please fill out all fields.")
      return
    }

    const data = new FormData()
    Object.keys(formData).forEach((key) => {
      if (key === "material" && key !== "customMaterial") {
        if (formData[key] === "Other") {
          data.append(key, formData["customMaterial"])
        } else {
          data.append(key, formData[key])
        }
      } else if (
        formData[key] !== null &&
        (key !== "file" || newFileSelected) &&
        key !== "customMaterial"
      ) {
        data.append(key, formData[key])
      }
    })

    const request = project
      ? api.put(`${PROJECT_LC_URL}${formData.pk}`, data)
      : api.post(PROJECT_LC_URL, data)

    request
      .then(() => {
        sendReceivedEmail()
        toggle()
        resetState()
      })
      .catch((error) => {
        console.error(
          `Error ${project ? "editing" : "creating"} request: `,
          error
        )
        setErrorMessage(
          `Error ${project ? "editing" : "creating"} request. Please try again.`
        )
      })
  }

  const defaultIfEmpty = (value) => (value === "" ? "" : value)

  return (
    <Form onSubmit={handleSubmit}>
      {errorMessage && <Alert color="danger">{errorMessage}</Alert>}

      <h5>Request Information:</h5>
      <Row>
        <Col>
          <FormGroup>
            <Label for="customerName">Name:</Label>
            <Input
              type="text"
              name="customerName"
              onChange={onChange}
              value={defaultIfEmpty(formData.customerName)}
              disabled={!create}
            />
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <Label for="customerEmail">Email:</Label>
            <Input
              type="text"
              name="customerEmail"
              onChange={onChange}
              value={defaultIfEmpty(formData.customerEmail)}
              disabled={!create}
            />
          </FormGroup>
        </Col>
      </Row>

      {create ? (
        <>
          <h5 className="mt-4">Cut File:</h5>
          <FormGroup>
            <Label for="file">We accept .dxf, .svg, or .pdf</Label>
            <Input
              type="file"
              name="file"
              onChange={onChange}
              disabled={!create}
            />
          </FormGroup>
        </>
      ) : (
        <Row>
          <Col>
            <strong>Project File: </strong>
            {project.file_name}
          </Col>
          <Col>
            <Button href={project.file} download>
              Download
            </Button>
          </Col>
        </Row>
      )}

      <h5 className="mt-5">Cut Settings:</h5>
      <Row>
        <Col>
          <FormGroup>
            <Label for="quantity">Cut Quantity:</Label>
            <Input
              className="w-25"
              type="number"
              name="quantity"
              onChange={onChange}
              value={formData.quantity}
            />
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <Label for="materialSource">Material Source:</Label>
            <Input
              type="select"
              name="materialSource"
              onChange={onChange}
              value={defaultIfEmpty(formData.materialSource)}
            >
              {SOURCE_CHOICES.map((choice) => (
                <option key={choice[0]} value={choice[0]}>
                  {choice[1]}
                </option>
              ))}
            </Input>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <FormGroup>
            <Label for="thickness">Material Thickness:</Label>
            <Input
              className="w-25"
              type="select"
              name="thickness"
              onChange={onChange}
              value={defaultIfEmpty(formData.thickness)}
            >
              {THICKNESS_CHOICES.map((choice) => (
                <option key={choice} value={choice}>
                  {choice}
                </option>
              ))}
            </Input>
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <Label for="material">Material:</Label>
            <Input
              type="select"
              name="material"
              onChange={onChange}
              value={defaultIfEmpty(formData.material)}
            >
              {MATERIAL_CHOICES.map((choice) => (
                <option key={choice} value={choice}>
                  {choice}
                </option>
              ))}
            </Input>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          {formData.material === "Other" && (
            <FormGroup>
              <Label for="customMaterial">Custom Material:</Label>
              <Input
                type="text"
                name="customMaterial"
                onChange={onChange}
                value={defaultIfEmpty(formData.customMaterial)}
              />
            </FormGroup>
          )}
        </Col>
      </Row>
      {formData.materialSource == "SP" && (
        <FormGroup>
          <Label for="comments">Who did you give your material to?</Label>
          <Input
            name="whereMaterial"
            type="textarea"
            placeholder="Please put their name here"
            onChange={onChange}
            value={defaultIfEmpty(formData.whereMaterial)}
          />
        </FormGroup>
      )}

      <FormGroup>
        <Label for="comments">Additional comments:</Label>
        <Input
          name="comments"
          type="textarea"
          placeholder="If there are any additional settings you'd like to specify (layer height, speed, supports, orientation, etc), please note them here. Most prints will be fine with the default settings we use."
          onChange={onChange}
          value={defaultIfEmpty(formData.comments)}
        />
      </FormGroup>
      <Button>Send</Button>
    </Form>
  )
}

export default NewProjectLCForm
