import React, { useState, useEffect, useRef } from "react"

import { Form, FormGroup, Label, Input, Button } from "reactstrap"

const CheckoutForm = ({ handleCardDataSubmit }) => {
  const [input, setInput] = useState("")
  const inputRef = useRef(null)

  // Handle the input field change event
  const handleOnChange = (e) => {
    e.preventDefault()
    setInput(e.target.value)
  }

  // Handle the form submission event
  const handleSubmit = (e) => {
    e.preventDefault()
    console.log(extractCardNumber(input))
    handleCardDataSubmit(extractCardNumber(input))
  }

  // Extract the card number from the swipe data
  const extractCardNumber = (swipeData) => {
    // Regular expression to match the first 9 digits
    const match = swipeData.match(/\d{9}/)
    return match ? match[0] : null // Return the first match or null if not found
  }

  // Focus the input field when modal is opened
  useEffect(() => {
    // Focus the input field when the component mounts
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }, [])

  return (
    <div>
      <Form onSubmit={handleSubmit}>
        <FormGroup>
          <Label for="id">Enter BYU ID</Label>
          <Input
            type="text"
            name="userID"
            onChange={handleOnChange}
            value={input}
            innerRef={inputRef}
          />
        </FormGroup>
        <Button>Send</Button>
      </Form>
    </div>
  )
}

export default CheckoutForm
