//Created by Logan Cropper 8/9/24

import React, { useState } from "react"
import { Container, Row, Col, Button } from "reactstrap"
import { Tab, Tabs, TabList, TabPanel } from "react-tabs"

import ".././Body.css"

import ProjectPanel from "./ProjectPanel"

const ProjectsTab = ({ setMainSelectedTab }) => {
  const [selectedTab, setSelectedTab] = useState(0)

  const handleTabChange = (index) => {
    setSelectedTab(index)
  }

  const handleCartButton = () => {
    setMainSelectedTab(4)
  }

  return (
    <Container className="main-container" fluid="true">
      <Tabs selectedIndex={selectedTab} onSelect={handleTabChange}>
        <div className="title-text">
          <Row className="align-items-center justify-content-between">
            <Col>
              <h2>Project Requests</h2>
            </Col>
            <Col xs="auto">
              <Button
                size="lg"
                color="success"
                style={{
                  fontSize: "1.25rem",
                  padding: "0.75rem 1.5rem",
                }}
                onClick={handleCartButton}
              >
                Cart
              </Button>
            </Col>
          </Row>
        </div>
        <TabList>
          <Tab>3D Print</Tab>
          <Tab>PCB Mill</Tab>
          <Tab>Laser Cut</Tab>
        </TabList>
        <TabPanel>
          <ProjectPanel type="TDP" />
        </TabPanel>
        <TabPanel>
          <ProjectPanel type="PCB" />
        </TabPanel>
        <TabPanel>
          <ProjectPanel type="LC" />
        </TabPanel>
      </Tabs>
    </Container>
  )
}

export default ProjectsTab
