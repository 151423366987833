import React, { useState, useEffect } from "react"
import { Container, Col, Row } from "reactstrap"
import { createColumnHelper } from "@tanstack/react-table"

import { RECEIPTS_URL } from "../../constants"
import api from "../../api"
import Table from "../Table/Table"
import ReceiptsModal from "./ReceiptsModal"

const ImprovedReceiptsTab = () => {
  const [receipts, setReceipts] = useState([])
  const [total, setTotal] = useState(0)

  const columnHelper = createColumnHelper()

  const columns = [
    columnHelper.accessor("purchaserName", {
      cell: (info) => {
        if (info.getValue() == null) {
          return "Campus Card"
        } else {
          return info.getValue()
        }
      },

      header: () => <span>Purchaser</span>,
    }),
    columnHelper.accessor("purchaserID", {
      cell: (info) => info.getValue(),
      header: () => <span>NET ID</span>,
    }),
    columnHelper.accessor("groupPurchaseName", {
      cell: (info) => info.getValue(),
      header: () => <span>Group</span>,
    }),
    columnHelper.accessor("total", {
      cell: (info) => info.getValue(),
      header: () => <span>Total</span>,
    }),
    columnHelper.accessor("dateOfPurchase", {
      cell: (info) => info.getValue(),
      header: () => <span>Date of Purchase</span>,
    }),
    columnHelper.accessor("button", {
      cell: ({ row }) => (
        <ReceiptsModal
          purchase={row.original}
          total={row.original.total}
          groupName={row.original.groupPurchaseName}
        />
      ),
      header: () => <span></span>,
    }),
  ]

  const fetchReceipts = async () => {
    try {
      const data = await api.get(RECEIPTS_URL)
      setReceipts(data.data)
      console.log(data)
    } catch (err) {
      console.log("Failed to retrieve items")
    }
  }

  const resetState = () => {
    fetchReceipts()
  }

  useEffect(() => {
    resetState()
  }, [])

  return (
    <Container className="main-container" fluid="true">
      <div className="title-text">
        <Row className="align-items-center justify-content-between">
          <Col>
            <h2>Receipts</h2>
          </Col>
        </Row>
      </div>

      <Table
        searchable={true}
        columns={columns}
        parentData={receipts}
        paginate={true}
      />
    </Container>
  )
}

export default ImprovedReceiptsTab
