// Created by Logan Cropper 6/5/2024
import React, { useState } from "react"
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap"
import NewGroupForm from "./NewGroupForm"

const NewGroupModal = ({ create, resetState, group }) => {
  const [modal, setModal] = useState(false)

  const toggle = () => {
    setModal((prevModal) => !prevModal)
  }

  // Determine title and button text based on the `create` prop
  const title = create ? "Creating New Group" : "Editing Group"
  const buttonText = create ? "Create Group" : "Edit"
  const buttonClass = create ? "mb-4 mt-4 mr-4" : "mb-0 mt-0 mr-0"

  return (
    <>
      <Button color="primary" className={buttonClass} onClick={toggle}>
        {buttonText}
      </Button>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>{title}</ModalHeader>
        <ModalBody>
          <NewGroupForm resetState={resetState} toggle={toggle} group={group} />
        </ModalBody>
      </Modal>
    </>
  )
}

export default NewGroupModal
