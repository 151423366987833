import React, { useState, useEffect } from "react"
import { createColumnHelper } from "@tanstack/react-table"

import { Container, Row, Col } from "reactstrap"

import CheckInModal from "./CheckInModal"
import Table from "../Table/Table"
import { LOANED_ITEMS_URL } from "../../constants"
import api from "../../api"

const ImprovedLoansTab = () => {
  const [loans, setLoans] = useState([])
  const [showOverdue, setShowOverdue] = useState(false) // State for the switch

  const columnHelper = createColumnHelper()

  const columns = [
    columnHelper.accessor("name", {
      cell: (info) => info.getValue(),
      header: () => <span>Loaned Item Name</span>,
    }),
    columnHelper.accessor("serialNumber", {
      cell: (info) => info.getValue(),
      header: () => <span>Serial Number</span>,
    }),
    columnHelper.accessor("quantity", {
      cell: (info) => info.getValue(),
      header: () => <span>Quantity</span>,
    }),
    columnHelper.accessor("loaneeName", {
      cell: (info) => info.getValue(),
      header: () => <span>Loanee Name</span>,
    }),
    columnHelper.accessor("netID", {
      cell: (info) => info.getValue(),
      header: () => <span>Net ID</span>,
    }),
    columnHelper.accessor("checkoutDate", {
      cell: (info) => info.getValue(),
      header: () => <span>Checkout Date</span>,
    }),
    columnHelper.accessor("returnDate", {
      cell: ({ row }) =>
        row.original.overdue ? (
          <span style={{ color: "red" }}>{row.original.returnDate}</span>
        ) : (
          <span style={{ color: "green" }}>{row.original.returnDate}</span>
        ),
      header: "Return Date",
    }),
    columnHelper.accessor("pk", {
      cell: (info) => (
        <CheckInModal pk={info.getValue()} resetState={resetState} />
      ),
      header: () => <span></span>,
    }),
  ]

  const fetchLoansData = async () => {
    try {
      const data = await api.get(LOANED_ITEMS_URL)
      setLoans(data.data)
    } catch (err) {
      console.log("Failed to retrieve items")
    }
  }

  const resetState = () => {
    fetchLoansData()
  }

  useEffect(() => {
    resetState()
  }, [])

  // Filter loans based on the switch state
  const filteredLoans = showOverdue
    ? loans.filter((loan) => loan.overdue)
    : loans

  return (
    <Container className="main-container" fluid="true">
      <div className="title-text">
        <Row className="align-items-center justify-content-between">
          <Col>
            <h2>Loans</h2>
          </Col>
          <Col xs="auto">
            <label>
              <input
                type="checkbox"
                checked={showOverdue}
                onChange={() => setShowOverdue((prev) => !prev)}
              />
              Show Overdue Items Only
            </label>
          </Col>
        </Row>
      </div>
      <Table
        searchable={true}
        columns={columns}
        parentData={filteredLoans}
        paginate={true}
      />
    </Container>
  )
}

export default ImprovedLoansTab
