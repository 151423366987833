//Created by Logan Cropper 7/31/24

import React, { useState, Fragment } from "react"
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap"
import NewProjectLCForm from "./NewProjectLCForm"
import styles from "../Lander/Lander.module.css"

function NewProjectLCModal({ project, resetState, create }) {
  const [modal, setModal] = useState(false)

  const toggle = () => {
    setModal(!modal)
  }

  // const mode = props.mode

  // var title = "Edit"
  // var button = (
  //   <Button color="info" className="m-4" onClick={toggle}>
  //     About
  //   </Button>
  // )

  // if (mode === "create") {
  //   title = "New Laser Cut Request"
  //   button = (
  //     <Button color="primary" className="m-4" onClick={toggle}>
  //       {title}
  //     </Button>
  //   )
  // } else if (mode === "lander") {
  //   title = "New Laser Cut Request"
  //   button = (
  //     <Button block className={styles["project-button"]} onClick={toggle}>
  //       <img
  //         src={`${process.env.PUBLIC_URL}/static/images/lasercut_image.png`}
  //         alt="Project 1"
  //         className={styles["project-image"]}
  //       />
  //       <div className={styles["project-text"]}>Laser Cut Request</div>
  //     </Button>
  //   )
  // }

  return (
    <Fragment>
      {!create ? (
        <Button color="primary" className="m-4" onClick={toggle}>
          Edit
        </Button>
      ) : (
        <Button block className={styles["project-button"]} onClick={toggle}>
          <img
            src={`${process.env.PUBLIC_URL}/static/images/lasercut_image.png`}
            alt="Project 1"
            className={styles["project-image"]}
          />
          <div className={styles["project-text"]}>Laser Cut Request</div>
        </Button>
      )}
      <Modal isOpen={modal} toggle={toggle} size="lg">
        <ModalHeader toggle={toggle}>
          {create ? "New Laser Cut Request" : "Edit"}
        </ModalHeader>
        <ModalBody>
          <NewProjectLCForm
            toggle={toggle}
            project={project}
            resetState={resetState}
            create={create}
          />
        </ModalBody>
      </Modal>
    </Fragment>
  )
}

export default NewProjectLCModal
