import React from "react"
import {
  Document,
  Page,
  Text,
  View,
  PDFViewer,
  Image,
  pdf,
} from "@react-pdf/renderer"

import styles from "./ReceiptsPDFStyles"

const ReceiptsPDF = ({ purchase, groupName, total }) => {
  return (
    // <PDFViewer showToolbar={true} width={"100%"} height={600}>
    <Document title={`ELC_Receipt_${purchase.dateOfPurchase}`}>
      <Page size={{ width: 2.69 * 72, height: 10 * 72 }} style={styles.page}>
        {/* Title header */}
        <View style={styles.header}>
          <Text>BYU ELC Checkout</Text>
        </View>
        {/* Date */}
        <View style={styles.date}>
          <Text>{purchase.dateOfPurchase}</Text>
        </View>
        {/* Purchaser info */}
        <View style={styles.purchase_info}>
          <Text>Name: {purchase.purchaserName}</Text>
          <Text>ID: {purchase.purchaserID}</Text>
          {groupName && <Text>Purchasing Group: {groupName}</Text>}
        </View>
        {/* Item table header */}
        <View style={styles.table_header}>
          <Text style={styles.table_column_1}>Qty</Text>
          <Text style={styles.table_column_2}>Item</Text>
          <Text style={styles.table_column_3}>Price</Text>
          <Text style={styles.table_column_4}>Subtotal</Text>
        </View>
        {/* Purchased items */}
        {purchase.receiptItems.map((item) => (
          <View key={item.name} style={styles.table_row}>
            <Text style={styles.table_column_1}>{item.quantity}</Text>
            <Text style={styles.table_column_2}>
              {item.name.replace(/([-/.\s])/g, "$1\n")}
            </Text>
            <Text style={styles.table_column_3}>
              {parseFloat(item.price).toFixed(2)}
            </Text>
            <Text style={styles.table_column_4}>
              {(parseFloat(item.quantity) * parseFloat(item.price)).toFixed(2)}
            </Text>
          </View>
        ))}
        {/* Price total footer */}
        <View style={styles.table_footer}>
          <Text style={styles.table_column_1}></Text>
          <Text style={styles.table_column_2}></Text>
          <Text style={styles.table_column_3}>Total:</Text>
          <Text style={styles.table_column_4}>
            {parseFloat(total).toFixed(2)}
          </Text>
        </View>
        <View style={{ marginTop: 20, alignItems: "center" }}>
          <Image
            src={`${process.env.PUBLIC_URL}/static/images/logo.png`}
            style={{ width: 150, height: 100 }}
          />
        </View>
      </Page>
    </Document>
    // </PDFViewer>
  )
}

export default ReceiptsPDF
